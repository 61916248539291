import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API } from '@renderbus/common/utils'
import { Layout, SEO, Wrapper } from '@renderbus/common/components'
import {
  BannerBgWrap,
  BannerSubTitle,
  BannerTitle,
  BannerWrap,
  DownloadButton,
  DownloadButtonWrap,
  DownloadContent,
  DownloadContentImg,
  DownloadFeatures,
  DownloadInfoTitle,
  DownloadInfoTitleSub,
  DownloadInfoWrap,
  DownloadItemWrap,
  DownloadLink,
  DownloadSystemText,
  DownloadTabsWrap,
  DownloadTip,
  DownloadVersion,
  SectionTitleLocal,
  Video,
  VideoSection,
  VideoWrap,
  LinkText,
} from './download.atom'
import BannerBg from '../images/download/banner-bg.png'
import BannerBgMb from '../images/download/banner-bg-mb.png'
import AnimationPng from '../images/download/animation-png.png'
import WindowsIcon from '../images/download/windows-icon.png'
import LinuxIcon from '../images/download/linux-icon.png'
import WindowsGreenIcon from '../images/download/windows-green-icon.png'
import LinuxGreenIcon from '../images/download/linux-green-icon.png'
import DownloadMbIcon from '../images/download/download-mb-icon.png'
import QRCodeMb from '../images/download/QR-code-mb.png'
import { isTest } from '@renderbus/common/service'
import { useMedia } from 'use-media'

const Download = props => {
  const {
    location,
    pageContext: { topThreeShare },
  } = props
  const [animationInfoArr, setAnimationInfoArr] = useState([])
  const isMobile = useMedia({ maxWidth: 768 })

  useEffect(() => {
    const instance = axios.create({
      baseURL: API.auth.downloadHost,
    })
    Promise.all([instance.get(API.auth.windowsVersion), instance.get(API.auth.linuxVersion)]).then(
      res => {
        const animationInfo = res.map(item => item.data)
        setAnimationInfoArr(animationInfo)
      },
    )
  }, [])

  const downloadSoftWare = (link, version) => {
    if (link) {
      let a = document.createElement('a')
      a.href = link
      if (version) {
        a[`data-verison`] = version
      }
      a.click()
    }
  }

  return (
    <Layout location={location} topThreeShare={topThreeShare}>
      <SEO
        title='Renderbus云渲染客户端下载|自助式渲染工具下载'
        keywords='Renderbus渲染客户端下载，Renderbus渲染工具下载，自助式渲染工具下载，渲染客户端下载'
        description='免费体验Renderbus渲染服务，下载自助式渲染客户端，自动上传下载渲染文件，节省您的渲染时间，提升渲染工作效率.'
        sharePostSlug='download.html'
      />
      <BannerWrap>
        <BannerBgWrap>
          <img src={isMobile ? BannerBgMb : BannerBg} alt='' />
        </BannerBgWrap>
        <BannerTitle>下载影视动画云渲染客户端</BannerTitle>
        <BannerSubTitle>一键批量提交渲染 · 自助式渲染 · 渲染进程实时预览一键下载</BannerSubTitle>
        <DownloadTabsWrap>
          <DownloadContent>
            <DownloadContentImg>
              <img src={AnimationPng} alt='下载影视动画云渲染客户端' />
            </DownloadContentImg>
            <DownloadInfoWrap>
              <DownloadInfoTitle>Renderbus云渲染客户端</DownloadInfoTitle>
              <DownloadInfoTitleSub>
                开发者：深圳市瑞云科技股份有限公司｜
                <a href='https://www.renderbus.com/support-new/privacy-policy/'>隐私协议</a>
              </DownloadInfoTitleSub>
              <DownloadButtonWrap>
                {animationInfoArr.map((item, index) => (
                  <DownloadItemWrap key={item.download_url}>
                    {!isMobile ? (
                      <DownloadButton
                        onClick={() => downloadSoftWare(item.download_url, item.version)}
                        imgWidth={index === 0 ? '24px' : '25px'}
                        imgHeight={index === 0 ? '24px' : '30px'}
                      >
                        <img src={index === 0 ? WindowsIcon : LinuxIcon} alt='' />
                        {index === 0 ? '下载Windows客户端' : '下载Linux客户端'}
                      </DownloadButton>
                    ) : (
                      <DownloadSystemText imgWidth={30} imgHeight={index === 0 ? 30 : 36}>
                        <img src={index === 0 ? WindowsGreenIcon : LinuxGreenIcon} alt='' />
                        <span>{index === 0 ? 'Windows客户端' : 'Linux客户端'}</span>
                      </DownloadSystemText>
                    )}
                    <DownloadVersion>{`${item.update_time} | 版本号${item.version} | 支持${
                      item.update_content.version.includes('支持')
                        ? item.update_content.version.split('支持')[1].trim()
                        : item.update_content.version.split('support')[1].trim()
                    }`}</DownloadVersion>
                    <DownloadFeatures>
                      <div>功能介绍：</div>
                      {Object.values(item.update_content.log).map(item => (
                        <div key={item}>{item}</div>
                      ))}
                    </DownloadFeatures>
                  </DownloadItemWrap>
                ))}
              </DownloadButtonWrap>
              <DownloadLink href='https://task.renderbus.com/sso'>
                立即在线体验网页渲染
              </DownloadLink>
              {isMobile && (
                <>
                  <DownloadTip>
                    <img src={DownloadMbIcon} alt='' />
                    请到PC端下载客户端
                  </DownloadTip>
                  <DownloadContentImg mbWidth={400} mbHeight={400}>
                    <img src={QRCodeMb} alt='' />
                  </DownloadContentImg>
                  <DownloadInfoWrap>
                    <DownloadInfoTitle>微信扫一扫</DownloadInfoTitle>
                    <DownloadInfoTitleSub mbFontSize={30}>
                      随时随地监控作业进度{' '}
                    </DownloadInfoTitleSub>
                  </DownloadInfoWrap>
                </>
              )}
            </DownloadInfoWrap>
          </DownloadContent>
        </DownloadTabsWrap>
      </BannerWrap>
      <VideoWrap>
        <VideoSection>
          <LinkText>
            影视动画用户专用云渲染客户端，效果图用户请点击
            <a
              className='to-renderings'
              href={
                isTest()
                  ? 'http://pre.renderbus.com:8081/xgt-download.html'
                  : 'https://www.renderbus.com/xgt-download.html'
              }
            >
              下载效果图云渲染客户端
            </a>
          </LinkText>
          <Wrapper id='pcVideo' name='pcVideo'>
            <SectionTitleLocal>云渲染客户端视频教程</SectionTitleLocal>
            <Video key='pcVideo' preload='auto' controls>
              <source
                src='https://contentful-big-file.oss-cn-beijing.aliyuncs.com/客户端/Renderbu-Maya-客户端03.22.mp4'
                type='video/mp4'
              ></source>
            </Video>
          </Wrapper>
          <DownloadLink href='https://www.renderbus.com/support-new/animated-client-video-tutorial/'>
            点击查看更多软件使用教程
          </DownloadLink>
        </VideoSection>
        <VideoSection>
          <Wrapper id='webVideo' name='webVideo'>
            <SectionTitleLocal>网页提交渲染视频教程</SectionTitleLocal>
            <Video key='webVideo' preload='auto' controls>
              <source
                src='https://contentful-big-file.oss-cn-beijing.aliyuncs.com/网页端/Renderbus-网页端maya-04.25.mp4'
                type='video/mp4'
              ></source>
            </Video>
          </Wrapper>
          <DownloadLink href='https://www.renderbus.com/support-new/web-submission-video-tutorial/'>
            点击查看更多软件使用教程
          </DownloadLink>
        </VideoSection>
      </VideoWrap>
    </Layout>
  )
}

export default Download
